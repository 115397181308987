*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.main-div{
    background-color: rgb(105, 180, 196);
    height:100vh;
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 2px solid black;

}
.child-div{
    background-color: rgb(108, 105, 140);
    height:300vh  ;
    width: 500vh;
    

}

.eachItem{
    border-radius: 10px;
    display:flex;
    justify-content: space-between;
    background-color: rgb(97,153,153,1);
    color:white;
    border: 2px solid rgb(46, 41, 41);
    
    padding:11px 15px;
    margin-top: 5px;
    box-sizing: border-box;
    text-align: center;
    
    

    
}
 .list-items{
    background-color: rgb(126, 231, 228);
    padding: 20px;
    color: white;
    border: 10px solid rgb(87, 124, 127);
    border-radius: 15px;
    height:450px;
    overflow-y: scroll;

} 
.clearItem{
    background-color:rgb(198, 78, 78);
    margin:auto;
    margin-top: 40px;
    color:rgb(245, 242, 241);
    padding:8px 20px;
    opacity:.8;
    cursor:pointer;
    width:150px;
    border-radius:8px; 
    margin-bottom: 50px;
    position:sticky;
    top:800px;
}
.clearItem:hover{
    background-color: brown;
}
.items{
    display: block;
    margin: auto;
    
}
